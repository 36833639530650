import React from 'react';
import { graphql } from 'gatsby';
import { arrayOf, object, shape, string } from 'prop-types';
import parseHtml from '../../../utils/parseHtml';
import { BrandSelector, Footer, Hero, SEO } from '../../../components';

const CompatibilityByMakePage = ({ data }) => {
  const {
    seo,
    path,
    heading,
    subheading,
    headerImage,
    content,
    theme,
  } = data.allGraphCmsPage.edges[0].node;
  const brands = data.allGraphCmsGuide.edges;

  const staticText = {
    brandSelection: {
      heading: 'Check by make, model, year',
    },
    backToMakesCta: {
      text: 'Back to makes',
    },
  };

  return (
    <>
      <SEO seo={seo} path={path} />
      <Hero heading={heading} subheading={subheading} image={headerImage} theme={theme} />
      <div className="bg-white">
        <div className="container py-12 md:pt-16 md:pb-2 space-y-16">
          <h2>{staticText.brandSelection.heading}</h2>
          <BrandSelector brands={brands} />
          <div className="mb-4">{parseHtml(content[0].text.html)}</div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CompatibilityByMakePage;

CompatibilityByMakePage.propTypes = {
  data: shape({
    allGraphCmsPage: shape({
      edges: arrayOf(
        shape({
          node: shape({
            path: string.isRequired,
            heading: string.isRequired,
            subheading: string,
            headerImage: shape({
              // eslint-disable-next-line react/forbid-prop-types
              gatsbyImageData: object.isRequired,
            }),
            seo: shape({
              title: string.isRequired,
              metaDescription: string.isRequired,
            }).isRequired,
            content: arrayOf(
              shape({
                text: shape({
                  html: string.isRequired,
                }).isRequired,
              }).isRequired,
            ),
            theme: string,
          }).isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
    allGraphCmsGuide: shape({
      edges: arrayOf(
        shape({
          node: shape({
            path: string.isRequired,
            region: string.isRequired,
            manufacturer: string.isRequired,
            manufacturerLogo: shape({
              // eslint-disable-next-line react/forbid-prop-types
              gatsbyImageData: object.isRequired,
            }),
          }).isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

export const CompatibilityByMakePageQuery = graphql`
  query CompatibilityByMakePage {
    allGraphCmsPage(filter: { path: { eq: "compatibility/makes" } }) {
      edges {
        node {
          heading
          subheading
          path
          headerImage {
            gatsbyImageData(placeholder: NONE)
          }
          seo {
            title
            metaDescription
          }
          theme
          content {
            ... on GraphCMS_TextBlock {
              remoteTypeName
              text {
                html
              }
            }
          }
        }
      }
    }
    allGraphCmsGuide {
      edges {
        node {
          path
          region
          manufacturer
          manufacturerLogo {
            gatsbyImageData(placeholder: NONE)
          }
        }
      }
    }
  }
`;
